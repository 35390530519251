@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Neonderthaw&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.fancy-scrollbar::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}

.fancy-scrollbar::-webkit-scrollbar-thumb {
  width: 10px;
  background: #AAA;
  border-radius: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* accent = emerald-400 */
/* accent light = emerald-50 */
/* text = slate-800 */
/* background primary = slate-100 */
/* background secondary(home page only) = slate-200 */
/* border = slate-300 */
/* shadow = shadow-md */